/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Layout from "./Layout";
import theme from "../styles/theme";

const styling = (dark, space, centered) => css`
  margin: 55px 0;
  ${
    dark
      ? css`
          padding: 55px 0;
          background: #15243f;
          color: white;

          ${theme.breakpoints.sm} {
            margin: 80px 0;
          }
        `
      : ""
  }

  ${
    dark || space
      ? css`
          margin: 120px 0;

          ${theme.breakpoints.sm} {
            margin: 80px 0;
          }
        `
      : ""
  }

  ${
    centered
      ? css`
          .content-container {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        `
      : ""
  }
`;

const ContentBlock = ({ children, dark, space, centered }) => (
  <section css={styling(dark, space, centered)}>
    <Layout.Container>{children}</Layout.Container>
  </section>
);

export default ContentBlock;
