/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import ContentBlock from "./ContentBlock";
import RichText from "./RichText";
import Image from "./Image";
import theme from "../styles/theme";
import { useInView } from "react-hook-inview";

const styling = (inView) => css`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: 700px;
  margin: 0 auto;

  ${theme.breakpoints.sm} {
    flex-direction: column;
  }

  .picture {
    overflow: visible;
    ${theme.breakpoints.sm} {
      transform: translateX(19px);
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-200px) translateY(17px);
      opacity: 0;
      background: #ec652f;
      z-index: -1;
      transition: all 2s;

      ${theme.breakpoints.sm} {
        width: calc(100% - 19px);
      }
    }

    img {
      max-width: 100%;
      height: auto;
      transition: all 2s;
      transform: translateX(-240px);
      ${theme.breakpoints.sm} {
        max-width: calc(100% - 19px);
      }
    }
  }

  .image-text-left {
    text-align: right;
    ${theme.breakpoints.sm} {
      margin-bottom: 40px;
    }
  }

  .image-text-right {
    padding-left: 47px;
    display: flex;
    align-items: flex-end;
    transform: translateY(20px);
    opacity: 0;
    transition-delay: 1s;
    transition: all 1s;
  }

  .image-text-left,
  .image-text-right {
    width: 50%;
    ${theme.breakpoints.sm} {
      width: 100%;
    }
  }

  .image-text-sig {
    width: 100%;
    padding-left: 50%;
    margin-top: 40px;
    text-align: right;
    transform: translateY(20px);
    opacity: 0;
    transition: all 1s;

    ${theme.breakpoints.sm} {
      padding-left: 0;
    }
  }

  ${inView
    ? css`
        .picture {
          &:after {
            transform: translateX(-19px) translateY(17px);
            opacity: 1;
          }

          img {
            transform: translateX(0) translateY(0);
            opacity: 1;
          }
        }
        .image-text-right,
        .image-text-sig {
          transform: translateY(0);
          opacity: 1;
        }
      `
    : ""}
`;

const ImageQuote = ({ children, image, quote }) => {
  const [ref, inView] = useInView({ threshold: 0.5 });

  return (
    <ContentBlock space>
      <div css={styling(inView)} ref={ref}>
        <div className="image-text-left">
          <Image image={image} />
        </div>
        <div className="image-text-right">
          <RichText>
            <p className="quote">{quote}</p>
          </RichText>
        </div>
        <div className="image-text-sig">{children}</div>
      </div>
    </ContentBlock>
  );
};

export default ImageQuote;
