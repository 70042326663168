/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import theme from "../styles/theme";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";

const styling = (cover) => css`
  display: inline-block;
  position: relative;
  overflow: hidden;

  img {
    display: block;
  }
  .lazyload,
  .lazyloading {
    opacity: 0;
  }

  .ls-blur-up-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(10px);
    transition: all 1000ms;
  }

  .ls-blur-up-img.ls-inview.ls-original-loaded {
    opacity: 0;
  }

  ${cover &&
    css`
      height: 100%;
      width: 100%;
      picture {
        height: 100%;
        width: 100%;

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      .ls-blur-up-img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    `}
`;

const getLowResImageUrl = (url, dimensions) =>
  `${url.split("?")[0]}?w=100&h=${(dimensions.height / dimensions.width) *
    100}`;

const Image = ({
  image: { url, thumbnails, dimensions },
  cover = false,
  alt,
  className = "",
}) =>
  url ? (
    <div css={styling(cover)} className={`${className} picture`}>
      <picture>
        {thumbnails?.mobile && (
          <source
            data-srcset={thumbnails?.mobile.url}
            data-lowsrc={`${getLowResImageUrl(
              url,
              thumbnails?.mobile.dimensions
            )}`}
            width={thumbnails?.mobile.dimensions.width}
            height={thumbnails?.mobile.dimensions.height}
            alt={alt}
            media={`(max-width: ${theme.breakpoints.values.sm}px)`}
          />
        )}
        {thumbnails?.tablet && (
          <source
            data-srcset={thumbnails?.tablet.url}
            data-lowsrc={`${getLowResImageUrl(
              url,
              thumbnails?.tablet.dimensions
            )}`}
            width={thumbnails?.tablet.dimensions.width}
            height={thumbnails?.tablet.dimensions.height}
            alt={alt}
            media={`(max-width: ${theme.breakpoints.values.md}px)`}
          />
        )}
        <img
          className="lazyload"
          data-lowsrc={getLowResImageUrl(url, dimensions)}
          src={getLowResImageUrl(url, dimensions)}
          data-src={url}
          alt={alt}
          width={dimensions.width}
          height={dimensions.height}
        />
      </picture>
    </div>
  ) : null;

export default Image;
