/* eslint-disable camelcase */
/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { graphql } from "gatsby";
import { Parser } from "html-to-react";
import Teaser from "../components/Teaser";
import Image from "../components/Image";
import InviewFadeIn from "../components/InviewFadeIn";

const htmlToReactParser = new Parser();

const TeaserSlice = ({ items }) => (
  <InviewFadeIn>
    <Teaser.Container>
      {items.map(({ image, title, description, link, linktext }) => (
        <Teaser>
          <Image image={image} />
          <h4>{title.text}</h4>
          <p>{description.text}</p>
          {link.url && (
            <a href={link.url} target="_blank">
              {linktext}
            </a>
          )}
        </Teaser>
      ))}
    </Teaser.Container>
  </InviewFadeIn>
);

export const sliceId = "teaser";
export default TeaserSlice;

export const stageFragment = graphql`
  fragment TeaserData on PrismicPageBodyTeaser {
    id
    slice_type
    primary {
      visible
      navigationtitle {
        text
      }
    }
    items {
      image {
        url
        thumbnails {
          mobile {
            url
            dimensions {
              height
              width
            }
          }
        }
        dimensions {
          height
          width
        }
      }
      linktext
      link {
        url
      }
      title {
        text
      }
      description {
        text
      }
    }
  }
`;
