/* eslint-disable camelcase */
/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { graphql } from "gatsby";
import { Parser } from "html-to-react";
import Icon from "../components/Icon";

const htmlToReactParser = new Parser();

const IconsSlice = ({ items }) => (
  <Icon.Container>
    {items.map(({ text, icon }, index) => (
      <Icon image={icon} index={index}>
        {text}
      </Icon>
    ))}
  </Icon.Container>
);

export const sliceId = "icons";
export default IconsSlice;

export const stageFragment = graphql`
  fragment IconsData on PrismicPageBodyIcons {
    id
    slice_type
    primary {
      visible
      navigationtitle {
        text
      }
    }
    items {
      text
      icon {
        url
        thumbnails {
          mobile {
            url
            dimensions {
              height
              width
            }
          }
        }
        dimensions {
          height
          width
        }
      }
    }
  }
`;
