/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import Image from "./Image";
import { Tween, Timeline } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import Layout from "./Layout";
import theme from "../styles/theme";

const styling = css`
  background: #15243f;
  width: 100%;
  color: #ec652f;
  font-weight: bold;
  padding: 20px 0;
  text-align: center;
  line-height: 1.5;
  margin-top: 150px;

  .content-container {
    display: flex;

    ${theme.breakpoints.sm} {
      flex-direction: column;
    }
  }
`;

const leftStyling = css`
  flex: 0 0 33%;
  ${theme.breakpoints.sm} {
    order: 3;
  }
`;
const centerStyling = css`
  flex: 0 0 33%;
  ${theme.breakpoints.sm} {
    order: 2;
    margin-bottom: 20px;
  }
`;
const rightStyling = css`
  flex: 0 0 33%;
  ${theme.breakpoints.sm} {
    order: 1;
    margin-bottom: 20px;
  }
`;

const Footer = ({ children }) => (
  <footer css={styling}>
    <Layout.Container>{children}</Layout.Container>
  </footer>
);

Footer.Left = ({ children }) => <div css={leftStyling}>{children}</div>;
Footer.Center = ({ children }) => <div css={centerStyling}>{children}</div>;
Footer.Right = ({ children }) => <div css={rightStyling}>{children}</div>;

export { Footer };
