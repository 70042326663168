/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { Parser } from "html-to-react";
import { RichText as PrismicRichText } from "prismic-reactjs";
import linkResolver from "../utils/linkResolver";
import Quote from "../assets/img/quote.png";
import theme from "../styles/theme";

const htmlToReactParser = new Parser();

const styling = css`
  hyphens: auto;

  .quote {
    font-size: 28px;
    font-family: "Britannic";
    text-align: right;
    background: url(${Quote});
    background-repeat: no-repeat;
    padding-top: 50px;

    ${theme.breakpoints.sm} {
      background-size: 50px auto;
      font-size: 24px;
      padding-top: 20px;
    }
  }

  h2 {
    font-size: 24px;
    margin-bottom: 0.5em;
  }

  h3 {
    margin-bottom: 0.5em;
  }

  p + h2,
  p + h3,
  p + h4,
  p + p {
    margin-top: 1em;
  }
`;
const RichText = ({
  children,
  raw,
  HyperLink = (props) => <a {...props}>{props.children}</a>,
}) => {
  if (raw?.length === 1) {
    if (raw[0].text === "") {
      return null;
    }
  }

  return (
    <div css={styling}>
      {raw && <PrismicRichText render={raw} linkResolver={linkResolver} />}
      {children}
    </div>
  );
};

export default RichText;
