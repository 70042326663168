/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import ContentBlock from "./ContentBlock";
import Image from "./Image";
import theme from "../styles/theme";
import { useInView } from "react-hook-inview";

const styling = (inView, index) => css`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  text-align: center;
  justify-content: center;

  .picture {
    flex: 0 0 auto;
  }

  img {
    margin-bottom: 20px;
    transform: scale(0);
    transition: all 1s cubic-bezier(1, 0, 0, 1);
    opacity: 0;
    transition-delay: ${inView ? index * 0.1 : 0}s;
    ${theme.breakpoints.sm} {
      margin-bottom: 0;
    }
  }

  .text {
    transform: translateY(-20px);
    opacity: 0;
    transition: all 1s cubic-bezier(1, 0, 0, 1);
    transition-delay: ${inView ? index * 0.1 : 0}s;
  }

  ${inView
    ? css`
        img {
          transform: scale(1);
          opacity: 1;
        }
        .text {
          transform: translateY(0);
          opacity: 1;
        }
      `
    : ""}

  ${theme.breakpoints.sm} {
    flex: 1 1 auto;
    & + & {
      margin-top: 30px;
    }
  }
`;

const stylingContainer = css`
  display: flex;

  ${theme.breakpoints.sm} {
    flex-direction: column;
  }
`;

const Icon = ({ children, image, index }) => {
  const [ref, inView] = useInView({ rootMargin: "-100px", threshold: 0.5 });
  return (
    <div css={styling(inView, index)} ref={ref}>
      <Image image={image} />
      <div className="text">{children}</div>
    </div>
  );
};

Icon.Container = ({ children }) => (
  <ContentBlock dark>
    <div css={stylingContainer}>{children}</div>
  </ContentBlock>
);

export default Icon;
