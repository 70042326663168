/* eslint-disable camelcase */
/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { graphql } from "gatsby";
import Stage from "../components/Stage";

const StageSlice = ({ logo, items }) => (
  <Stage backgrounds={items.map((item) => item.background)} center={logo} />
);

export const sliceId = "stage";
export default StageSlice;

export const stageFragment = graphql`
  fragment StageData on PrismicPageBodyStage {
    id
    primary {
      visible
      navigationtitle {
        text
      }
      logo {
        thumbnails {
          mobile {
            url
            dimensions {
              height
              width
            }
          }
        }
        url
        dimensions {
          height
          width
        }
      }
    }
    slice_type
    items {
      background {
        url
        thumbnails {
          mobile {
            url
            dimensions {
              height
              width
            }
          }
        }
        dimensions {
          height
          width
        }
      }
    }
  }
`;
