/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import { useInView } from "react-hook-inview";

const styling = (inView) => css`
  opacity: 0;
  transform: translateY(20px);
  transition: all 1s;

  ${inView
    ? css`
        opacity: 1;
        transform: translateY(0);
      `
    : ""}
`;

const InviewFadeIn = ({ children }) => {
  const [ref, inView] = useInView({ threshold: 0 });

  return (
    <div ref={ref}>
      <div css={styling(inView)}>{children}</div>
    </div>
  );
};

export default InviewFadeIn;
