/* eslint-disable camelcase */
/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { graphql } from "gatsby";
import { Parser } from "html-to-react";
import Image from "../components/Image";
import ContentBlock from "../components/ContentBlock";
import InviewFadeIn from "../components/InviewFadeIn";

const styling = css`
  img {
    max-width: 100%;
  }
`;

const ImageSlice = ({ image }) => (
  <ContentBlock centered>
    <InviewFadeIn>
      <Image css={styling} image={image} />
    </InviewFadeIn>
  </ContentBlock>
);

export const sliceId = "image";
export default ImageSlice;

export const stageFragment = graphql`
  fragment ImageData on PrismicPageBodyImage {
    id
    primary {
      visible
      navigationtitle {
        text
      }
      image {
        thumbnails {
          mobile {
            url
            dimensions {
              height
              width
            }
          }
        }
        url
        dimensions {
          height
          width
        }
      }
    }
    slice_type
  }
`;
