/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import ContentBlock from "./ContentBlock";
import Image from "./Image";
import theme from "../styles/theme";

const styling = css`
  display: flex;
  background: white;
  font-weight: bold;
  text-align: center;

  ${theme.breakpoints.sm} {
    flex-direction: column;
  }
`;
const stylingColumn = css`
  flex: 1 1 0;
  padding: 25px;

  p + p {
    margin-top: 1em;
  }

  a,
  a:visited {
    color: inherit;

    &:hover {
      color: #ec652f;
    }
  }

  ${theme.breakpoints.sm} {
    order: 2;
  }
`;
const stylingImage = css`
  flex: 1 1 0;
  position: relative;

  .picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  ${theme.breakpoints.sm} {
    order: 1;
    flex: 0 0 auto;
    .picture {
      padding-bottom: 55%;
    }
  }
`;

const Card = ({ children }) => (
  <ContentBlock>
    <div css={styling}>{children}</div>
  </ContentBlock>
);

Card.Image = ({ image }) => (
  <div css={stylingImage}>
    <Image image={image} />
  </div>
);
Card.Column = ({ children }) => <div css={stylingColumn}>{children}</div>;

export default Card;
