import React, { useState } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import Helmet from "react-helmet";
import { kebabCase } from "lodash";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import { uniqueId } from "lodash";
import Layout from "../components/Layout";
import Slices from "../slices/_map";
import Footer from "./Footer";
import Header from "./Header";
import RichText from "../components/RichText";

const PageTemplate = (props) => {
  const { data } = props;
  const content = data.prismicPage.data;
  const body = data.prismicPage.data.body.filter(({ primary }) => primary.visible);
  console.log("PageTemplate -> content", content);
  const footer = data.prismicFooter.data;
  const header = data.prismicHeader.data;
  console.log(header);
  // const news = data.allPrismicNews.edges

  return (
    <>
      <Helmet>
        {/* eslint-enable */}
        <html lang={data.prismicPage.lang.split("-")[0]} />
        {/* <meta property="og:type" content="website" />
        <meta property="og:title" content={content.og_title} />
        <meta property="og:description" content={content.og_description} />
        <meta property="og:url" content={`http://preos.de/${linkResolver(data.prismicPage)}`} />
        <meta property="og:site_name" content="PREOS Real Estate AG" />
        <meta property="og:image" content={content.og_image.url} />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta> */}
      </Helmet>
      <Layout>
        <Header
          links={header.links}
          logo={header.logo}
          iconlinks={header.iconlinks}
          scrollLinks={content.body.map(({ primary }) =>
            primary.navigationtitle && primary.navigationtitle.text !== "" ? (
              <Link
                to={kebabCase(primary.navigationtitle.text)}
                spy
                smooth="easeInOutCubic"
                offset={-50}
                duration={1000}
                key={`${kebabCase(primary.navigationtitle.text)}__nav`}
              >
                {primary.navigationtitle.text}
              </Link>
            ) : null
          )}
        />
        <main>
          <Slices body={body} lang={data.prismicPage.lang}></Slices>
        </main>
        <Footer column1={footer.column1} column2={footer.column2} column3={footer.column3} />
        {/* <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/5a40ccc3-8feb-472f-ad71-e1a51e14b254/cd.js"
        type="text/javascript"
        async
      ></script> */}

        <cookie-message
          background-color="#faf9f7"
          color="#15243f"
          highlight-color="#ec652f"
          secondary-color="#cccccc"
          highlight-text-color="white"
          hover-color="#c75021"
          google-tag-id={header.analytics}
          button-allow-all-text={header.cookiebuttonall}
          button-allow-selection-text={header.cookiebuttonselection}
        >
          <h1>{header.cookieheadline}</h1>
          <RichText raw={header.cookiemessage.raw} />
          <p slot="functional">{header.cookiefunctional}</p>
          <p slot="tracking">{header.cookiemarketing}</p>
        </cookie-message>
      </Layout>
    </>
  );
};

export default withPreview(PageTemplate);

export const pageQuery = graphql`
  query PageQuery($id: String) {
    prismicPage(id: { eq: $id }) {
      type
      uid
      lang
      alternate_languages {
        lang
        uid
        type
      }
      data {
        body {
          ... on PrismicPageBodyStage {
            ...StageData
          }
          ... on PrismicPageBodyText {
            ...TextData
          }
          ... on PrismicPageBodyTextleft {
            ...TextLeftData
          }
          ... on PrismicPageBodyTeaser {
            ...TeaserData
          }
          ... on PrismicPageBodyBackgroundteaser {
            ...BackgroundTeaserData
          }
          ... on PrismicPageBodyImagequote {
            ...ImageQuoteData
          }
          ... on PrismicPageBodyIcons {
            ...IconsData
          }
          ... on PrismicPageBodyCard {
            ...CardData
          }
          ... on PrismicPageBodyImage {
            ...ImageData
          }
        }
      }
    }
    prismicHeader {
      ... on PrismicHeader {
        ...HeaderData
      }
    }
    prismicFooter {
      ... on PrismicFooter {
        ...FooterData
      }
    }
  }
`;
