/* eslint-disable camelcase */
/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { graphql } from "gatsby";
import { Parser } from "html-to-react";
import Text from "../components/Text";
import InviewFadeIn from "../components/InviewFadeIn";

const htmlToReactParser = new Parser();

const TextSlice = ({ content }) => (
  <InviewFadeIn>
    <Text>{htmlToReactParser.parse(content.html)}</Text>
  </InviewFadeIn>
);

export const sliceId = "text";
export default TextSlice;

export const stageFragment = graphql`
  fragment TextData on PrismicPageBodyText {
    id
    primary {
      visible
      navigationtitle {
        text
      }
      content {
        html
      }
    }
    slice_type
  }
`;
