/* eslint-disable camelcase */
/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { graphql } from "gatsby";
import { Parser } from "html-to-react";
import ImageQuote from "../components/ImageQuote";

const htmlToReactParser = new Parser();

const ImageQuoteSlice = ({ quote, signature, image }) => (
  <ImageQuote image={image} quote={quote}>
    {htmlToReactParser.parse(signature.html)}
  </ImageQuote>
);

export const sliceId = "imagequote";
export default ImageQuoteSlice;

export const stageFragment = graphql`
  fragment ImageQuoteData on PrismicPageBodyImagequote {
    id
    slice_type
    primary {
      visible
      navigationtitle {
        text
      }
      quote
      signature {
        html
      }
      image {
        url
        thumbnails {
          mobile {
            url
            dimensions {
              height
              width
            }
          }
        }
        dimensions {
          height
          width
        }
      }
    }
  }
`;
