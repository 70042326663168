/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import ContentBlock from "./ContentBlock";
import theme from "../styles/theme";

const styling = css`
  text-align: center;
  margin-left: 22px;
  flex: 1 1 0;

  ${theme.breakpoints.sm} {
    & + & {
      margin-top: 30px;
    }
  }

  .picture {
    background: white;
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    max-width: 290px;
    margin: 0 auto 25px;

    ${theme.breakpoints.sm} {
      margin-bottom: 5px;
    }
  }

  h4 {
    font-family: inherit;
  }
`;

const containerStyling = css`
  display: flex;
  margin-left: -22px;

  ${theme.breakpoints.sm} {
    flex-direction: column;
  }
`;

const Teaser = ({ children }) => <div css={styling}>{children}</div>;

Teaser.Container = ({ children }) => (
  <ContentBlock>
    <div css={containerStyling}>{children}</div>
  </ContentBlock>
);

export default Teaser;
