/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import theme from "../styles/theme";

const styling = (backgroundImage) => css`
  background: url(${backgroundImage});
  height: 250px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 120px 0;

  ${theme.breakpoints.sm} {
    background-attachment: unset;
  }

  &:after {
    content: "";
    background: rgba(0, 0, 0, 0.33);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .background-teaser-content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

const BackgroundTeaser = ({ children, backgroundImage }) => (
  <div css={styling(backgroundImage.url)}>
    <div className="background-teaser-content">{children}</div>
  </div>
);

export default BackgroundTeaser;
