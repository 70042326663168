/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import Image from "./Image";
import { Tween, Timeline } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import theme from "../styles/theme";

const styling = css`
  position: relative;
  height: 100vh;
  overflow: hidden;
  margin-top: -72px;

  .wrapper {
    overflow: hidden;
    height: 100vh;
  }

  ${theme.breakpoints.sm} {
    margin-top: -50px;
  }
`;

const backgroundStyling = (active) => css`
  position: absolute;
  height: 100%;
  width: 100%;
  transition: all 2s;
  ${active
    ? css`
        opacity: 1;
      `
    : css`
        opacity: 0;
      `}

  .picture,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &:after {
    content: "";
    background: rgba(0, 0, 0, 0.47);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const centerStyling = css`
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;

  img {
    max-width: calc(100% - 40px);
    height: auto;
    display: inline-block !important;
  }
`;

const Stage = ({ backgrounds, center }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1 === backgrounds.length ? 0 : index + 1));
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div css={styling}>
      {backgrounds.map((background, i) => (
        <div css={backgroundStyling(i === index)} key={`background-${i}`}>
          <Image image={background} />
        </div>
      ))}
      <div css={centerStyling}>
        <Image image={center} />
      </div>
    </div>
  );
};

export default Stage;
