/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { graphql } from "gatsby";
import { Link } from "@material-ui/core";
import { uniqueId } from "lodash";
import { Parser } from "html-to-react";
import { Header as HeaderComponent } from "../components/Header";
import linkResolver from "../utils/linkResolver";
import Image from "../components/Image";

const htmlToReactParser = new Parser();

const Header = ({ links, logo, scrollLinks, iconlinks }) => {
  // const isActive = (currentPage, link) => {
  //   let active = false;

  //   if (currentPage && link && link !== "/") {
  //     if (currentPage.indexOf(link) > -1) {
  //       active = true;
  //     }
  //   } else if (link === "/") {
  //     if (currentPage === link) {
  //       active = true;
  //     }
  //   }

  //   return active;
  // };

  return (
    <HeaderComponent>
      <HeaderComponent.Logo>
        <img src={logo.url} />
      </HeaderComponent.Logo>
      <HeaderComponent.Nav>
        {scrollLinks}
        {links.map(({ link, title }) => (
          <a href={link.url}>{title.text}</a>
        ))}
      </HeaderComponent.Nav>
      <HeaderComponent.Meta>
        {iconlinks.map(({ link, icon }) => (
          <a href={link.url} target={link.target}>
            <Image
              image={{
                url: icon.url,
                dimensions: {
                  width: 28,
                  height: 28,
                },
              }}
            />
          </a>
        ))}
      </HeaderComponent.Meta>
    </HeaderComponent>
  );
};

export default Header;

export const headerFragment = graphql`
  fragment HeaderData on PrismicHeader {
    data {
      iconlinks {
        icon {
          url
          dimensions {
            height
            width
          }
        }
        link {
          url
          target
        }
      }
      links {
        link {
          url
          document {
            ... on PrismicPage {
              uid
            }
          }
        }
        title {
          text
        }
      }
      logo {
        url
      }
      analytics
      cookieheadline
      cookiemessage {
        raw
      }
      cookiefunctional
      cookiemarketing
      cookiebuttonselection
      cookiebuttonall
    }
  }
`;
