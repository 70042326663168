/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import borderImage from "../assets/img/border.png";
import theme from "../styles/theme";

const styling = css`
  padding: 20px 38px;
  border-radius: 0;
  box-shadow: none;
  color: white !important;
  text-decoration: none;
  display: inline-block;
  text-transform: none;
  border: 10px solid pink;
  border-image: url(${borderImage}) 10;
  border-image-repeat: round;
  height: 146px;
  font-size: 60px;
  font-family: "Britannic";
  border-radius: 10px;
  transition: 0.3s all;

  ${theme.breakpoints.sm} {
    font-size: 40px;
    height: 120px;
  }

  &:hover {
    background: #ec662f;
  }
`;

const SpecialButton = ({ children, href }) => (
  <a href={href} css={styling} target="_blank">
    {children}
  </a>
);

export default SpecialButton;
