/* eslint-disable camelcase */
/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { graphql } from "gatsby";
import { Parser } from "html-to-react";
import Text from "../components/Text";
import Card from "../components/Card";

const htmlToReactParser = new Parser();

const CardSlice = ({ address, image }) => (
  <Card>
    <Card.Column>{htmlToReactParser.parse(address.html)}</Card.Column>
    <Card.Image image={image} />
  </Card>
);

export const sliceId = "card";
export default CardSlice;

export const stageFragment = graphql`
  fragment CardData on PrismicPageBodyCard {
    id
    slice_type
    primary {
      visible
      navigationtitle {
        text
      }
      address {
        html
      }
      image {
        url
        thumbnails {
          mobile {
            url
            dimensions {
              height
              width
            }
          }
        }
        dimensions {
          height
          width
        }
      }
    }
  }
`;
