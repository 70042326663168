/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import Image from "./Image";
import { Tween, Timeline } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import Layout from "./Layout";
import theme from "../styles/theme";

const styling = css`
  height: 92px;
  background: #15243f;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  .content-container {
    display: flex;

    ${theme.breakpoints.sm} {
      flex-direction: column;
      justify-content: center;
    }
  }

  ${theme.breakpoints.sm} {
    height: auto;
    padding: 10px 0;
  }
`;

const logoStyling = css`
  line-height: 0.1px;
  padding: 10px 0;

  ${theme.breakpoints.sm} {
    display: none;
  }
`;

const navStyling = css`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1 1 100%;
  padding-bottom: 5px;

  ${theme.breakpoints.sm} {
    margin-left: -20px;
    justify-content: center;
  }

  a {
    cursor: pointer;
    margin-left: 5.1%;
    font-size: 20px;
    color: white;

    &:hover {
      color: #ec652f;
    }
  }
`;

const metaStyling = (scrollTop) => css`
  position: fixed;
  right: 0;
  margin-top: 7px;
  width: 100%;
  top: 92px;
  ${scrollTop < (theme.breakpoints.sm ? 52 : 81)
    ? css`
        top: ${92 - scrollTop}px;
        ${theme.breakpoints.sm} {
          top: ${50 - scrollTop}px;
        }
      `
    : css`
        top: 0px;
      `}

  a {
    margin-left: 10px;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const Header = ({ children }) => (
  <header css={styling}>
    <Layout.Container>{children}</Layout.Container>
  </header>
);

Header.Logo = ({ children }) => (
  <a href="/" css={logoStyling}>
    {children}
  </a>
);

Header.Meta = ({ children }) => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <div css={metaStyling(scrollTop)}>
      <Layout.Container>{children}</Layout.Container>
    </div>
  );
};

Header.Nav = ({ children }) => <nav css={navStyling}>{children}</nav>;

export { Header };
