/* eslint-disable camelcase */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { Parser } from "html-to-react";
import { kebabCase } from "lodash";
import { Element } from "react-scroll";
import StageSlice, { sliceId as StageId } from "../slices/StageSlice";
import TextSlice, { sliceId as TextId } from "../slices/TextSlice";
import TextLeftSlice, { sliceId as TextLeftId } from "../slices/TextLeftSlice";
import TeaserSlice, { sliceId as TeaserId } from "../slices/TeaserSlice";
import BackgroundTeaserSlice, {
  sliceId as BackgroundTeaserId,
} from "../slices/BackgroundTeaserSlice";
import ImageQuoteSlice, {
  sliceId as ImageQuoteId,
} from "../slices/ImageQuoteSlice";
import IconsSlice, { sliceId as IconsId } from "../slices/IconsSlice";
import CardSlice, { sliceId as CardId } from "../slices/CardSlice";
import ImageSlice, { sliceId as ImageId } from "../slices/ImageSlice";

const htmlToReactParser = new Parser();

const Slices = ({ body, lang }) => (
  <>
    {body.map(({ slice_type, id, primary, items }) => (
      <Element
        name={
          primary.navigationtitle && kebabCase(primary.navigationtitle.text)
        }
        key={id}
      >
        {slice_type === StageId && <StageSlice {...primary} items={items} />}
        {slice_type === TextId && <TextSlice {...primary} />}
        {slice_type === TextLeftId && <TextLeftSlice {...primary} />}
        {slice_type === TeaserId && <TeaserSlice items={items} />}
        {slice_type === BackgroundTeaserId && (
          <BackgroundTeaserSlice {...primary} />
        )}
        {slice_type === ImageQuoteId && <ImageQuoteSlice {...primary} />}
        {slice_type === IconsId && <IconsSlice items={items} />}
        {slice_type === CardId && <CardSlice {...primary} />}
        {slice_type === ImageId && <ImageSlice {...primary} />}
      </Element>
    ))}
  </>
);

export default Slices;
