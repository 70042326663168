/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { graphql } from "gatsby";
import { Parser } from "html-to-react";
import { Link } from "@material-ui/core";
import { uniqueId } from "lodash";
import { Footer as FooterComponent } from "../components/Footer";
import RichText from "../components/RichText";
import linkResolver from "../utils/linkResolver";

const htmlToReactParser = new Parser();

const Footer = ({ column1, column2, column3 }) => (
  <FooterComponent>
    <FooterComponent.Left>
      <RichText raw={column1.raw} />
    </FooterComponent.Left>
    <FooterComponent.Center>
      <RichText raw={column2.raw} />
    </FooterComponent.Center>
    <FooterComponent.Right>
      <RichText raw={column3.raw} />
    </FooterComponent.Right>
  </FooterComponent>
);

export default Footer;

export const footerFragment = graphql`
  fragment FooterData on PrismicFooter {
    data {
      column1 {
        raw
      }
      column2 {
        raw
      }
      column3 {
        raw
      }
    }
  }
`;
