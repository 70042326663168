/* eslint-disable camelcase */
/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { graphql } from "gatsby";
import { Parser } from "html-to-react";
import Text from "../components/Text";

const htmlToReactParser = new Parser();

const TextLeftSlice = ({ content }) => (
  <Text left>{htmlToReactParser.parse(content.html)}</Text>
);

export const sliceId = "textleft";
export default TextLeftSlice;

export const stageFragment = graphql`
  fragment TextLeftData on PrismicPageBodyTextleft {
    id
    primary {
      visible
      navigationtitle {
        text
      }
      content {
        html
      }
    }
    slice_type
  }
`;
