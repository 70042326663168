/* eslint-disable camelcase */
/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { graphql } from "gatsby";
import { Parser } from "html-to-react";
import BackgroundTeaser from "../components/BackgroundTeaser";
import SpecialButton from "../components/SpecialButton";

const BackgroundTeaserSlice = ({ button, link, background }) => (
  <BackgroundTeaser backgroundImage={background}>
    <SpecialButton href={link.url}>{button}</SpecialButton>
  </BackgroundTeaser>
);

export const sliceId = "backgroundteaser";
export default BackgroundTeaserSlice;

export const stageFragment = graphql`
  fragment BackgroundTeaserData on PrismicPageBodyBackgroundteaser {
    id
    slice_type
    primary {
      visible
      navigationtitle {
        text
      }
      button
      link {
        url
      }
      background {
        thumbnails {
          mobile {
            url
            dimensions {
              height
              width
            }
          }
        }
        url
        dimensions {
          height
          width
        }
      }
    }
  }
`;
