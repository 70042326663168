/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import ContentBlock from "./ContentBlock";
import RichText from "./RichText";

const styling = (left) => css`
  text-align: center;

  ${left
    ? css`
        text-align: left;
      `
    : ""}
`;

const Text = ({ children, left }) => (
  <ContentBlock>
    <div css={styling(left)}>
      <RichText>{children}</RichText>
    </div>
  </ContentBlock>
);

export default Text;
